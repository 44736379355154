<template>
  <main class="main-page">
    <section class="page-section mb-3">
      <div class="container">
        <div class="flex align-items-center justify-content-center my-6">
          <div class="col-12 md:col-5 lg:col-5 sm:col-8 xs:col-11 comp-grid">
            <div class="flex align-items-center mb-3">
              <div v-if="!isSubPage" class="col-fixed">
                <Button @click="$router.go(-1)" label="" icon="pi pi-arrow-left"
                  class="p-button-sm p-button-rounded px-4" />
              </div>
              <div class="col-12 md:col-10">
                <div class="text-2xl text-primary font-bold">Login</div>
              </div>
            </div>

            <div class="card">
              <div class="pt-2">
                <form ref="observer" tag="form" @submit.prevent="startLogin()">
                  <div class="mb-2 w-full">
                    <InputText label="Phone Number Or Email" placeholder="Phone Number Or Email" class="w-full"
                      v-model.trim="formData.username" required="required" type="text" />
                  </div>
                  <div class="mb-2 w-full">
                    <Password label="Password" inputClass="w-full" :feedback="false" toggleMask class="w-full"
                      placeholder="Password" required="required" v-model="formData.password" />
                  </div>
                  <div class="flex justify-content-between align-items-center my-2">
                    <div class="flex align-items-center">
                      <Checkbox class="mr-2" id="rememberme" :binary="true" v-model="rememberUser" />
                      <label class="text-sm text-500" for="rememberme">Remember Me</label>
                    </div>
                    <div style="width: auto">
                      <router-link to="/index/forgotpassword"><Button class="p-button-danger p-button-text"
                          label="Reset Password?" /></router-link>
                    </div>
                  </div>
                  <Message v-if="loginErrorMsg" severity="error" :closable="true" @close="loginErrorMsg = null">
                    {{ loginErrorMsg.toString() }}
                  </Message>
                  <div class="text-center">
                    <Button label="Login" :loading="loading" class="w-full" type="submit">
                    </Button>
                  </div>
                </form>
              </div>
              <div class="mt-3">
                <Divider type="solid" />
                <div class="flex align-items-center justify-content-between">
                  <div class="col text-body1">Don't have an account?</div>
                  <div class="col text-right">
                    <router-link to="/index/register">
                      <Button icon="pi pi-user" label="Register Now" class="p-button-link" />
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import { useApp } from "@/composables/app";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const props = defineProps({
  pageName: {
    type: String,
    default: "index",
  },
  routeName: {
    type: String,
    default: "index",
  },
  isSubPage: {
    type: Boolean,
    default: false,
  },
});
const route = useRoute();
const router = useRouter();
const store = useStore();
const app = useApp();
const formData = reactive({
  username: "",
  password: "",
});
const loading = ref(false);
const pageReady = ref(true);
const loginErrorMsg = ref("");
const rememberUser = ref(false);
async function startLogin() {
  try {
    loading.value = true;
    loginErrorMsg.value = "";
    let url = "/auth/login";
    let payload = {
      formData,
      rememberUser: rememberUser.value,
      url,
    };
    const loginData = await store.dispatch("auth/login", payload);
    loading.value = false;
    if (loginData.token) {
      let nextUrl = route.query.nexturl || "/home";
      router.go(nextUrl);
      location.reload();
    } else {
      router.push(loginData.nextpage);
    }
  } catch (error) {
    loading.value = false;
    loginErrorMsg.value =
      error.request?.response || "Unable to establish connection...";
  }
}
onMounted(() => {
  const pageTitle = process.env.VUE_APP_NAME;
  app.setPageTitle(props.routeName, pageTitle);
});
</script>
<style></style>
